var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c("el-row", [
        _c("iframe", {
          attrs: {
            src: _vm.iframeUrl,
            frameborder: "1",
            width: "99%",
            height: "750px",
            seamless: "",
            sandbox:
              "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }