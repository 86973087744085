import $axios from '@/utils/request'

// 获取启用的标签
export function getTags(data) {
    const url = '/merchant/tag/getTags'
    return $axios.fGet(url, data)
}

// 添加标签
export function addTags(data) {
    const url = '/merchant/tag/addTag'
    return $axios.fPost(url, data)
}

// 删除或修改标签名称
export function updateTags(data) {
    const url = '/merchant/tag/updateTagById'
    return $axios.fPost(url, data)
}

// 获取标签组列表
export function getTagGroupList(data) {
    const url = '/merchant/tagGroup/list'
    return $axios.fPost(url, data)
}

// 编辑标签组
export function editTagGroupList(data) {
    const url = '/merchant/tagGroup/edit'
    return $axios.fPost(url, data)
}

// 创建标签组
export function addTagGroupList(data) {
    const url = '/merchant/tagGroup/add'
    return $axios.fPost(url, data)
}

// 删除标签组
export function delTagGroupList(data) {
    const url = '/merchant/tagGroup/del'
    return $axios.fGet(url, data)
}