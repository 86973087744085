<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <el-row>
      <iframe
        :src="iframeUrl"
        frameborder="1"
        width="99%"
        height="750px"
        seamless
        sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
      ></iframe>
    </el-row>
  </el-dialog>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    pID: {
      default: 0,
      type: Number
    }
  },
  data () {
    return {
      iframeUrl: ''
    }
  },
  methods: {
    openDialog () {
      let token = getToken()
      this.iframeUrl = process.env.VUE_APP_OJFM_IDE+ `?p=${this.pID}&token=${token}`
    },

    // 关闭
    close () {
      this.$emit('close')
    }
  }
}
</script>
