import { getTagGroupList, getTags } from '@/api/research/tag'

export default {
    data() {
        return {
            tagList: [], // 标签列表
            tagGroupList: [], // 标签组列表
            tagTreeGroup: [], // 标签树形结构数据, 包含标签组
            tagTreeData: [], // 过滤标签组->子项为空的

            tagUserList: [], // 标签列表
            tagUserGroupList: [], // 标签组列表
            tagUserTreeGroup: [], // 标签树形结构数据, 包含标签组
            tagUserTreeData: [], // 过滤标签组->子项为空的
        }
    },
    mounted() {
        this.getTagGroupList()
        this.getUserTagGroupList()
    },
    methods: {
        // 获取标签组
        getTagGroupList() {
            getTagGroupList({
                pageNum: 1,
                pageSize: 100,
                businessType: 'oj'
            }).then(res => {
                if (res.state === 'success') {
                    this.tagGroupList = res.body.list
                    const tagTreeGroup = []
                    this.tagGroupList.forEach((ele, idx) => {
                        tagTreeGroup.push({
                            ...ele
                        })
                    })
                    getTags({
                        pageNum: 1,
                        pageSize: 10000,
                        businessType: 'oj'
                    }).then(res => {
                        if (res.state === 'success') {
                            this.tagList = res.body
                            tagTreeGroup.forEach((ele, idx) => {
                                this.tagList.forEach((item, index) => {
                                    if (item.groupId === ele.id) {
                                        if (!ele.children) {
                                            ele.children = []
                                            ele.children.push({
                                                ...item
                                            })
                                        } else {
                                            ele.children.push({
                                                ...item
                                            })
                                        }
                                    }
                                })
                            })
                            this.tagTreeGroup = tagTreeGroup
                            this.tagTreeData = []
                            this.tagTreeGroup.forEach(ele => {
                                if (!ele.children) {} else {
                                    this.tagTreeData.push({
                                        ...ele
                                    })
                                }
                            })
                            console.log('tagTreeData', this.tagTreeData)
                        }
                    })
                }
            })
        },
        getUserTagGroupList() {
            getTagGroupList({
                pageNum: 1,
                pageSize: 100,
                businessType: '用户'
            }).then(res => {
                if (res.state === 'success') {
                    this.tagUserGroupList = res.body.list
                    const tagTreeGroup = []
                    this.tagUserGroupList.forEach((ele, idx) => {
                        tagTreeGroup.push({
                            ...ele
                        })
                    })
                    getTags({
                        pageNum: 1,
                        pageSize: 10000,
                        businessType: '用户'
                    }).then(res => {
                        if (res.state === 'success') {
                            this.tagUserList = res.body
                            tagTreeGroup.forEach((ele, idx) => {
                                this.tagUserList.forEach((item, index) => {
                                    if (item.groupId === ele.id) {
                                        if (!ele.children) {
                                            ele.children = []
                                            ele.children.push({
                                                ...item
                                            })
                                        } else {
                                            ele.children.push({
                                                ...item
                                            })
                                        }
                                    }
                                })
                            })
                            this.tagUserTreeGroup = tagTreeGroup
                            this.tagUserTreeData = []
                            this.tagUserTreeGroup.forEach(ele => {
                                if (!ele.children) {} else {
                                    this.tagUserTreeData.push({
                                        ...ele
                                    })
                                }
                            })
                            console.log('tagUserTreeData', this.tagUserTreeData)
                        }
                    })
                }
            })
        }
    }
}