import $axios from '@/utils/request'

// 获取问题列表
export function getQuestionList (data) {
  const url = '/question/getQuestionList'
  return $axios.fPost(url, data)
}

// 新增问题
export function appendQuestion (data) {
  const url = '/question/addQuestion'
  return $axios.fPost(url, data)
}

// 编辑问题
export function updateQuestion (data) {
  const url = '/question/updateQuestion'
  return $axios.fPost(url, data)
}

// 启/停/删除 问题
export function enableQues (data) {
  const url = '/question/updateQuestionState'
  return $axios.fPost(url, data)
}

// 查询提交记录
export function getSubmitHistory (data) {
  const url = '/question/getSubmitHistory'
  return $axios.fPost(url, data)
}

// 根据题库io查询提交记录
export function getSubmitHistoryByContest (data) {
  const url = '/question/getSubmitHistoryByContest'
  return $axios.fPost(url, data)
}

// 根据提交记录id查询提交详情
export function getSubmitHistoryItem (data) {
  const url = '/meredu/question/getSubmitHistoryItem'
  return $axios.fGet(url, data)
}

// 获取题目详情
export function getQuestionById (data) {
  const url = '/question/getQuestionById'
  return $axios.fGet(url, data)
}

// 获取最后提交代码
export function getLastSubmit (data) {
  const url = '/question/getLastSubmit'
  return $axios.fGet(url, data)
}

// 提交答题
export function submitSolutionCode (data) {
  const url = '/question/submitSolutionCode'
  return $axios.fPost(url, data)
}

// 获取题解视频ID
export function getVideo (data) {
  const url = '/question/getVideo'
  return $axios.fGet(url, data)
}
